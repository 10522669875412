<template>
  <Modal title="Add Field" @close="onCloseModal">
    <FormWrapper class="margin-bottom" :errors="errors" :show-submit="true" submit-text="Add Field"
      data-cy="add-field-form" @submit="onAdd">
      <FieldSettings v-model:field-local="fieldLocalRaw" :is-new="true" :field-new="fieldLocal" @submit="onAdd" />
    </FormWrapper>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Modal from '@/components/ui/Modal';
import FieldSettings from '@/components/fields/FieldSettings';
import FormWrapper from '@/components/ui/FormWrapper';
import RequestUtils from '@/components/util/RequestUtils';
import FieldUtils from '@/components/fields/FieldUtils';
import { trackEvent } from '@/lib/rudderstack-helper';
import { trackIntercomEvent, openChecklists } from '@/lib/intercom-helper';
import { isCreatedWithinLast15Minutes } from '@/lib/date-helper';

export default {
  components: {
    Modal,
    FieldSettings,
    FormWrapper,
  },
  mixins: [
    RequestUtils,
    FieldUtils,
  ],
  props: {
    insertIndex: {
      type: String,
      required: false,
      default: null,
    },
    fieldNew: {
      type: Object,
      default: null,
      required: false,
    },
    closeOnEvent: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'save'],
  data() {
    return {
      fieldLocalRaw: {
        type: Object,
        default: () => ({}),
      },
    };
  },
  computed: {
    ...mapGetters([
      'app',
      'activeObject',
      'actionsCompletedToShowChecklists',
    ]),
    fieldLocal() {
      return this.fieldNew || this.fieldMixin_createNewField({
        type: 'short_text',
        name: 'New Field',
      }, this.object);
    },
  },
  created() {
    this.fieldLocalRaw = JSON.parse(JSON.stringify(this.fieldLocal.raw()));
  },
  methods: {
    ...mapActions(['updateActionsCompleted']),
    onCloseModal() {
      if (this.closeOnEvent) {
        return this.$emit('close');
      }

      this.closeModal();
    },
    accountId() {
      return this.app.get('account').id;
    },
    closeModal() {
      this.$router.push(this.$route.path.split('/add')[0]);
    },
    ...mapActions(['updateActionsCompleted']),
    updateAddField() {
      this.updateActionsCompleted({ addField: true });
    },
    updateChecklistSeen(checklist) {
      this.updateActionsCompleted({ [checklist]: true });
    },
    onAdd() {
      const _this = this;
      // if an insert index isn't provided, insert at the end
      const insertIndex = (this.insertIndex !== null) ? Number(this.insertIndex) : this.object.fields.length;

      this.commitRequest({
        validate: () => this.fieldLocal.validate(this.fieldLocalRaw),
        request: () => this.fieldLocal.create(this.fieldLocalRaw, insertIndex),
        onSuccess: ({ field }) => {
          trackEvent('field_add', {
            field_name: field.name,
            field_type: field.type,
            field_id: field.key,
            field_category: field.category,
            field_origin: 'new',
          });
          trackIntercomEvent('field_add');
          if (isCreatedWithinLast15Minutes(this.accountId())) {
            if (!this.actionsCompletedToShowChecklists.checklistDiscoverCoreCapabilities) {
              openChecklists('discover_core_capabilities');
              _this.updateChecklistSeen('checklistDiscoverCoreCapabilities');
            }
            _this.updateAddField();
          } else if (!this.actionsCompletedToShowChecklists.addField && this.actionsCompletedToShowChecklists.addView) {
            _this.updateAddField();
            if (!this.actionsCompletedToShowChecklists.checklistWhoSeesWhat) {
              openChecklists('who_sees_what');
              _this.updateChecklistSeen('checklistWhoSeesWhat');
            }
          }

          if (!this.closeOnEvent) {
            this.closeModal();
          }

          this.$emit('save', this.fieldLocal);
        },
      });
    },
  },
};
</script>

<style lang="scss">
#field-current {
  font-size: 1.05em;

  strong {
    margin-right: 6px;
    vertical-align: middle;
  }
}
</style>
