import timeHelper from '@knack/time-helper';
import flagsmith from 'flagsmith';
import get from 'lodash/get';
import trimEnd from 'lodash/trimEnd';

import createPersistedState from 'vuex-persistedstate';
import Api from '@/lib/api-wrapper';
import { isUserAuthorizedToAccessApplication } from '@/lib/app-helper';
import {
  isCRM,
  isDevelopment,
} from '@/lib/env-helper';
import pages from './modules/pages';
import objects from './modules/objects';
import user from './modules/user';
import integrations from './modules/integrations';
import userInterface from './modules/userinterface';
import routes from './modules/routes';
import Application from './models/Application';
import Embed from './models/Embed';
import assets from './modules/assets';
import notifications from './modules/notifications';
import apiStore from './modules/api/api';
import pageStore from './modules/page/page';
import uiStore from './modules/ui/ui';
import externalScripts from './modules/externalScripts';
import checklists from '@/store/modules/checklists';
import toasts from '@/store/modules/toasts';

export default {
  state: {
    isLoadingRequest: false,
    isLoaded: false,
    activePage: null,
    activeView: null,
    activeObject: false,
    activeField: false,
    activeRoleFilter: false,
    app: {},
    appId: '',
    activeViewUnwatcher: null,
    isBuilderAuthenticated: false,
    ssoTestCode: null,
    reauthenticationRequired: false,
    // We only want to persist this in memory for the user's session
    cursorPosition: {},
    tempCode: {},
    featureFlags: {},
    apiBaseUrl: '',
    s3ApplicationCdnDomain: '',
    cache: {},
    actionsCompletedToShowChecklists: JSON.parse(localStorage.getItem('actionsCompletedToShowChecklists')) || {
      addField: false,
      addView: false,
      checklistWhoSeesWhat: false,
      checklistDiscoverCoreCapabilities: false,
    },
  },
  mutations: {
    commitRequest(state) {
      state.isLoadingRequest = true;
    },
    completeRequest(state) {
      state.isLoadingRequest = false;
    },
    loadApp(state, app) {
      state.app = new Application(app);

      // TODO: figure out if this is the best place to load styles
      state.app.buildDesignStyleSheet();
    },
    activePage(state, page) {
      state.activePage = page || null;
    },

    /**
     * Sets the active page to null.
     *
     * @param {object} state
     */
    clearActivePage(state) {
      state.activePage = null;
    },

    activeRoleFilter(state, role) {
      state.activeRoleFilter = role;
    },
    activeView(state, view) {
      state.activeView = (view || undefined);

      if (view) {
        view.setToActive();
      }
    },
    activeObject(state, obj) {
      state.activeObject = obj;
    },
    activeField(state, field) {
      state.activeField = field;
    },
    authenticateBuilder(state) {
      state.isBuilderAuthenticated = true;
    },
    unauthenticateBuilder(state) {
      state.isBuilderAuthenticated = false;
    },
    updateSSOTestCode(state, code) {
      state.ssoTestCode = code;
    },
    createDistribution(state, { distribution }) {
      if (!(distribution instanceof Embed)) {
        distribution = new Embed(distribution);
      }

      state.app.get('distributions').push(distribution);
    },
    deleteDistribution(state, distKey) {
      state.app.attributes.distributions = state.app.get('distributions').filter(({ key }) => key !== distKey);
    },
    updateDistribution(state, {
      distKey,
      embedName,
      pageName,
      design,
    }) {
      const distIndex = state.app.get('distributions').findIndex((dist) => dist.key === distKey);

      state.app.attributes.distributions[distIndex].name = embedName;
      state.app.attributes.distributions[distIndex].scene = pageName;
      state.app.attributes.distributions[distIndex].design = design;
    },
    createTask(state, { task }) {
      state.activeObject.attributes.tasks.push(task);
    },
    deleteTask(state, taskKey) {
      state.activeObject.attributes.tasks = state.activeObject.get('tasks').filter(({ key }) => key !== taskKey);
    },
    updateTask(state, { task }) {
      const taskIndex = state.activeObject.attributes.tasks.findIndex(({ key }) => task.key === key);

      state.activeObject.attributes.tasks[taskIndex] = task;
    },
    updateApplication(state, updatedApp) {
      // _.merge(state.app, appUpdates)
      state.app = new Application(updatedApp);
    },
    setReauthenticationRequired(state) {
      state.reauthenticationRequired = true;
    },
    clearReauthenticationRequired(state) {
      state.reauthenticationRequired = false;
    },
    setCursorPosition(state, { language, cursorPosition }) {
      state.cursorPosition[language] = cursorPosition;
    },
    setAppId(state, appId) {
      state.appId = appId;
    },
    setTempCode(state, { language, tempCode }) {
      state.tempCode[language] = tempCode;
    },
    setFeatureFlags(state, flags) {
      state.featureFlags = flags;
    },
    setApiBaseUrl(state, { subdomain, domain }) {
      if (isDevelopment()) {
        // On dev, the domain data returned from the server is incorrect, so use the ENV var instead.
        state.apiBaseUrl = trimEnd(process.env.VUE_APP_API_URL, '/').replace('/v1', '');

        return;
      }

      state.apiBaseUrl = `//${subdomain}.${domain}`;
    },
    setS3ApplicationCdnDomain(state, s3ApplicationCdnDomain) {
      state.s3ApplicationCdnDomain = s3ApplicationCdnDomain;
    },
    setCacheFlowsUrl(state, url) {
      const id = this.getters.getAccountAndAppSlug();
      const flowsCache = state.cache.flows || {};
      state.cache.flows = {
        ...flowsCache,
        [id]: {
          url,
          timestamp: Date.now(),
        },
      };
    },
    updateActionsCompleted(state, payload) {
      state.actionsCompletedToShowChecklists = {
        ...state.actionsCompletedToShowChecklists,
        ...payload,
      };
      localStorage.setItem('actionsCompletedToShowChecklists', JSON.stringify(state.actionsCompletedToShowChecklists));
    },
  },
  actions: {
    async bootstrapApp({ commit, dispatch }, { accountSlug, applicationSlug }) {
      commit('commitRequest');

      let builderData;

      try {
        builderData = await Api.getInitialBuilderData(accountSlug, applicationSlug);
      } catch (err) {
        const wasAppNotFound = err?.errors?.some(
          ({ message }) => message === 'Application not found',
        );

        const isAppUnavailable = err?.status === 503
          && err?.errors?.some(({ message }) => message === 'Application unavailable, please try again later');

        if (isAppUnavailable) {
          return {
            redirect: {
              path: '/app-maintenance',
            },
          };
        }

        // TODO: handle all error cases
        if (wasAppNotFound) {
          return {
            redirect: {
              path: '/app-not-found',
              query: {
                slug: applicationSlug,
              },
            },
          };
        }
      }

      log('Got initial builder data:');
      log(builderData);

      // Forward the user to /app-not-found if they aren't authorized. If the link is a CRM link,
      // we skip this check for troubleshooting customer apps in knackcrm.
      if (!isUserAuthorizedToAccessApplication(builderData.application.id) && !isCRM()) {
        log('User not authorized, redirecting to app-not-found');

        return {
          redirect: {
            path: '/app-not-found',
            query: {
              slug: applicationSlug,
            },
          },
        };
      }

      commit('setApiBaseUrl', {
        subdomain: builderData.api_subdomain,
        domain: builderData.api_domain,
      });

      commit('setS3ApplicationCdnDomain', builderData.application.s3_application_cdn_domain);

      try {
        await dispatch('setupApp', builderData.application);
      } catch (loadError) {
        log(loadError);

        return {
          redirect: {
            path: '/app-error',
          },
        };
      }

      commit('completeRequest');

      return {
        builderData,
      };
    },
    async setupApp({ commit, dispatch, state }, app) {
      if (app.id !== state.appId) {
        commit('setAppId', app.id);
        commit('routes/resetAllRoutes');
        commit('routes/resetQueries');
      }

      dispatch('checklists/init');
      commit('loadApp', app);
      commit('loadObjects', app.objects);
      await dispatch('page/loadPages', {
        pages: app.scenes,
        pageStructure: app.pageStructure,
      });

      delete app.scenes;
      delete app.objects;
      delete app.pageStructure;

      if (!window.Cypress || app.settings.enableFlagsmith) {
        // init flagsmith
        await flagsmith.init({
          environmentID: process.env.VUE_APP_FLAGSMITH_ID,
          enableAnalytics: true,
          preventFetch: true,
          onChange: (oldFlags, params) => {
            if (!params.flagsChanged) {
              return;
            }

            commit('setFeatureFlags', flagsmith.flags);
          },
        });

        // identify for feature flags
        await flagsmith.identify(app.account.id);

        // poll for feature flag changes every 60 minutes
        flagsmith.startListening(3600000);
      }

      // this is used to hide loader and render app
      state.isLoaded = true;
    },

    async setActiveViewByKey({ commit, dispatch, getters }, { viewKey }) {
      const newActiveView = (viewKey) ? getters.activePage?.getView(viewKey) : null;

      commit('activeView', newActiveView);

      if (newActiveView) {
        dispatch('setupViewWatcher');
      } else {
        dispatch('unwatchActiveView');
      }
    },

    unwatchActiveView({ state }) {
      if (state.activeViewUnwatcher) {
        state.activeViewUnwatcher();
        state.activeViewUnwatcher = null;
      }
    },

    async setupViewWatcher({
      commit,
      dispatch,
      getters,
      state,
    }) {
      // Make sure the previous watcher is unset.
      if (state.activeViewUnwatcher) {
        await dispatch('unwatchActiveView');
      }

      // The store watch() function returns an unwatch function that stops the watch.
      state.activeViewUnwatcher = this.watch(
        (watchState) => get(watchState, 'activeView.attributes'),
        () => {
          // If activeView still exists, this will set hasActiveUpdates if any attribute changes.
          if (state.activeView) {
            if (!getters.isCancelling) {
              commit('setViewHasActiveUpdates', true);
            }

            return;
          }

          // view no longer exists, remove watch handler
          dispatch('unwatchActiveView');
        },
        { deep: true },
      );
    },
    updateActionsCompleted({ commit }, payload) {
      commit('updateActionsCompleted', payload);
    },
  },
  getters: {
    app: (state) => state.app,
    appSlug: (state) => get(state, 'app.slug'),
    appName: (state) => get(state, 'app.name'),
    accountSlug: (state) => get(state, 'app.account.slug'),
    activePage: (state) => state.activePage,
    activeView: (state) => state.activeView,
    activeObject: (state) => state.activeObject,
    activeField: (state) => state.activeField,
    activeRoleFilter: (state) => state.activeRoleFilter,
    appIsLoaded: (state) => state.isLoaded,
    apiBaseUrl: (state) => state.apiBaseUrl,
    s3ApplicationCdnDomain: (state) => state.s3ApplicationCdnDomain,
    isBuilderAuthenticated: (state) => state.isBuilderAuthenticated,
    ssoTestCode: (state) => state.ssoTestCode,
    isGlobalLogin: (state) => state.app.users && state.app.users.scope && state.app.users.scope === 'application',
    isUsersEnabled: (state) => state.app.users && state.app.users.enabled,
    getViewDesignDefaults: (state) => (viewType) => state.app.getDesignDefaults(viewType),
    isLoadingRequest: (state) => state.isLoadingRequest,
    appTimezoneFormatted: (state) => (state.app.attributes ? timeHelper.getTimeZone(state.app.attributes) : null),
    reauthenticationRequired: (state) => state.reauthenticationRequired,
    getCursorPosition: (state) => (language) => state.cursorPosition[language],
    getTempCode: (state) => (language) => state.tempCode[language],
    accountStatusIsActive: (state) => state.app.account.status === 'active' || state.app.account.status === 'beta',
    accountStatusIsTrialExpired: (state) => state.app.isTrialExpired(),
    accountStatusIsFrozen: (state) => state.app.isFrozen(),
    accountStatusIsCancelled: (state) => state.app.account.status === 'cancelled',
    accountIsSuspended: (state) => state.app.isSuspended(),
    isSharedBuilder: (state) => state.app.isSharedBuilder(),
    hasFeature: (state) => (flag) => get(state, `featureFlags.${flag}.enabled`) === true,
    getFeatureValue: (state) => (flag) => get(state, `featureFlags.${flag}.value`),
    isObjectNameUnique: (state) => (name) => !state.objects.all.find((object) => object.get('name') === name),
    getAccountAndAppSlug: (state) => () => {
      const accountSlug = get(state, 'app.account.slug');
      const appSlug = get(state, 'app.slug');
      return `${accountSlug}-${appSlug}`;
    },
    getFlowsUrl: (state, getters) => () => {
      const id = getters.getAccountAndAppSlug();
      const flowsCache = state.cache.flows || {};
      const flows = flowsCache[id] || null;
      if (!flows || !flows.url || !flows.timestamp) {
        return null;
      }
      const expirationTime = 4 * 60 * 60 * 1000;
      if (flows.timestamp && (Date.now() - flows.timestamp) < expirationTime) {
        return flows.url;
      }
      return null;
    },
    actionsCompletedToShowChecklists: (state) => state.actionsCompletedToShowChecklists,

  },
  modules: {
    pages,
    objects,
    integrations,
    user,
    userInterface,
    routes,
    assets,
    notifications,
    externalScripts,
    checklists,
    toasts,
    ...apiStore,
    ...pageStore,
    ...uiStore,
  },
  plugins: [
    createPersistedState({
      key: 'userInterface',
      paths: [
        'userInterface.showViewNames',
        'userInterface.collapseViews',
        'userInterface.pagePreviewType',
        'userInterface.pagePreviewId',
      ],
      getItem: (key) => localStorage.get(key),
      setItem: (key, value) => localStorage.set(key, value),
      removeItem: (key) => localStorage.remove(key),
    }),
    createPersistedState({
      key: 'ui',
      paths: [
        'ui.leftPanelWidth',
        'ui.filterSchemaFields.schemaFieldFilterByProperties',
      ],
      getItem: (key) => localStorage.get(key),
      setItem: (key, value) => localStorage.set(key, value),
      removeItem: (key) => localStorage.remove(key),
    }),
    createPersistedState({
      key: 'knack',

      // persistent part of state tree
      paths: [
        'routes.records',
        'routes.pages',
        'routes.settings',
        'routes.bots',
        'pages.useSampleData',
        'appId',
      ],
      getItem: (key) => localStorage.get(key),
      setItem: (key, value) => localStorage.set(key, value),
      removeItem: (key) => localStorage.remove(key),
    }),
    createPersistedState({
      key: 'checklists',
      paths: [
        'checklists.hasClosedChecklistModal',
      ],
      getItem: (key) => localStorage.get(key),
      setItem: (key, value) => localStorage.set(key, value),
      removeItem: (key) => localStorage.remove(key),
    }),
  ],
};
