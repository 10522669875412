<template>
  <Overlay
    id="asset-viewer-overlay"
    @close="onCloseAssetViewer"
  >
    <template #title>
      <h1 id="kn-asset-header">
        {{ fileName }}
      </h1>
    </template>
    <div
      id="asset-viewer"
      class="kn-asset"
    >
      <div class="kn-asset-preview">
        <div
          v-if="isPreviewable"
          class="kn-asset-content"
          :style="assetPreviewStyles"
        >
          <div v-if="isTextFile">
            {{ textFileContent }}
          </div>
          <iframe
            v-if="isHtmlFile"
            id="asset-preview-frame"
            :src="previewUrl"
            style="background: #FFFFFF; padding: 20px;"
            height="100%"
            width="100%"
          />
          <img
            v-if="isImageFile"
            :src="previewUrl"
            style="max-height: 100%; max-width: 100%;"
          >
          <audio
            v-if="isMp3File"
            :id="`audio_${fileName}`"
            controls
          >
            <source
              :src="previewUrl"
              type="audio/mp3"
            >
          </audio>
          <embed
            v-if="isPdfFile"
            :src="previewUrl"
            width="100%"
            height="100%"
            type="application/pdf"
            :title="fileName"
          >
          <div v-if="noPreview">
            Unable to preview file at this time. Please try again later.
          </div>
        </div>
        <div v-else>
          <Icon
            type="page"
            style="height: 64px; width: 64px;"
          />
          <br>
          {{ trans("This file type is not currently previewable.") }}
          <br>
          {{ trans("Click the download button below to save and view the file.") }}
        </div>
        <p id="kn-asset-footer">
          <strong>{{ fileSize }}&nbsp;</strong>
          <a
            class="kn-file-download button medium save"
            @click="onClickHandleDownload"
          >
            <Icon type="download-export" />&nbsp;<strong>{{ trans("Download") }}</strong>
          </a>
          <iframe
            id="iFrameDownload"
            ref="iFrameDownload"
            style="display: none;"
          />
        </p>
      </div>
    </div>
  </Overlay>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import debounce from 'lodash/debounce';
import Icon from '@/components/ui/Icon';
import Overlay from '@/components/ui/Overlay';
import RequestUtils from '@/components/util/RequestUtils';
import TranslationUtils from '@/components/renderer/mixins/TranslationUtils.js';

export default {
  components: {
    Icon,
    Overlay,
  },
  mixins: [
    RequestUtils,
    TranslationUtils,
  ],
  data() {
    return {
      previewableFileExtensions: [
        'txt',
        'html',
        'png',
        'pdf',
        'jpg',
        'jpeg',
        'gif',
        'bmp',
        'mp3',
      ],
      textFileContent: 'Loading...',
    };
  },
  computed: {
    ...mapGetters('assets', [
      'assetTokenKey',
      'assetMetadata',
    ]),
    assetPreviewStyles() {
      switch (this.fileExtension) {
        case 'txt':
          return {
            'background-color': '#FFFFFF',
            'text-align': 'left',
            padding: '20px',
            'max-height': '75%',
            'max-width': '80%',
            'overflow-x': 'auto',
            'white-space': 'pre-wrap',
          };

        case 'html':
          return {
            height: '75%',
            width: '80%',
          };

        default:
          return {};
      }
    },
    assetId() {
      return this.assetMetadata._id || this.assetMetadata.id;
    },
    fileName() {
      return this.assetMetadata ? this.assetMetadata.filename : 'Loading...';
    },
    fileExtension() {
      return this.fileName.slice((this.fileName.lastIndexOf('.') - 1 >>> 0) + 2).toLowerCase();
    },
    fileSize() {
      return this.assetMetadata ? this.formatFileSize(this.assetMetadata.size) : 0;
    },
    type() {
      return this.assetMetadata ? this.assetMetadata.type : null;
    },
    isTextFile() {
      return this.fileExtension === 'txt';
    },
    isHtmlFile() {
      return this.fileExtension === 'html';
    },
    isImageFile() {
      return [
        'bmp',
        'gif',
        'jpg',
        'jpeg',
        'png',
      ].includes(this.fileExtension);
    },
    isMp3File() {
      return this.fileExtension === 'mp3';
    },
    isPdfFile() {
      return this.fileExtension === 'pdf';
    },
    noPreview() {
      return !(this.isTextFile || this.isHtmlFile || this.isImageFile || this.isMp3File || this.isPdfFile);
    },
    isPreviewable() {
      return this.fileName ? this.previewableFileExtensions.includes(this.fileExtension) : true;
    },
    baseDownloadUrl() {
      const apiUri = window.Knack.Api.api_uri.replace('/v1/', '/v2/');
      return `${apiUri}applications/${window.Knack.Api.application_id}/asset/${this.assetId}/download/`;
    },
    previewUrl() {
      return `${this.baseDownloadUrl}${this.assetTokenKey}?preview=true${this.previewUrlHash}`;
    },
    previewUrlHash() {
      // TODO: handle non-builder mode hash scenarios (table view, preview image asset, right click open in new tab)
      return `#kn-asset/${this.assetId}/${this.fileName}`;
    },
    textFileContentLoaded: {
      get() {
        return this.textFileContent;
      },
      set(newValue) {
        this.textFileContent = newValue;
      },
    },
  },
  created() {
    // .txt files require extra processing
    if (this.isTextFile) {
      this.setTextFileContent();
    }
  },
  methods: {
    ...mapActions('assets', [
      'setAssetTokenKey',
    ]),
    ...mapMutations('assets', [
      'resetAll',
    ]),
    onClickHandleDownload: debounce(function () {
      this.handleAssetDownload();
    }, 5000, {
      leading: true,
    }),
    onCloseAssetViewer() {
      this.resetAll();
    },
    formatFileSize(bytes, decimals) {
      if (bytes === 0) {
        return '0 Bytes';
      }

      const kilo = 1000; // 1024 or 1 kilo = 1000
      const sizes = [
        'Bytes',
        'KB',
        'MB',
        'GB',
        'TB',
        'PB',
      ];
      const int = Math.floor(Math.log(bytes) / Math.log(kilo));

      return `${parseFloat((bytes / Math.pow(kilo, int)).toFixed(decimals))} ${sizes[int]}`;
    },
    async setTextFileContent() {
      try {
        this.textFileContentLoaded = await (await fetch(this.previewUrl)).text();
      } catch (err) {
        throw err;
      }
    },
    downloadUrl() {
      return `${window.Knack.Api.api_uri}applications/${window.Knack.Api.application_id}/scene/${this.assetMetadata.pageKey}/view/${this.assetMetadata.viewKey}/field/${this.assetMetadata.fieldKey}/asset/${this.assetId}/download/${this.assetTokenKey}`;
    },
    handleAssetDownload() {
      // grab a new token for downloading the asset
      this.commitRequest({
        request: () => this.setAssetTokenKey({
          assetId: this.assetId,
          pageKey: this.assetMetadata.pageKey,
          viewKey: this.assetMetadata.viewKey,
          fieldKey: this.assetMetadata.fieldKey,
        }),
        onSuccess: () => {
          // set iFrame source to trigger file download
          this.$refs.iFrameDownload.src = `${this.baseDownloadUrl}${this.assetTokenKey}`;
        },
      });
    },
  },
};
</script>

<style lang="scss">
/*
  temporarily overwrite z-index to be above inline edit popover
  until we can get z-indices to sane levels
*/
#asset-viewer-overlay {
  z-index: 10001 !important;
}

#asset-viewer {
  text-align: center;
}

.kn-asset-preview {
  audio {
    height: 54px;
  }

  embed {
    height: 700px;
    width: 100%;
  }

  #kn-asset-footer {
    position: absolute;
    bottom: 1em;
    left: 0;
    width: 100%;
    margin: 0!important;
  }
}
</style>
