export function dateCreatedFromMongoId(id, options = null) {
  if (options) {
    const timestamp = parseInt(id.toString().substring(0, 8), 16) * 1000;
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('en-US', options).format(date);
  }
  return new Date(parseInt(id.toString().substring(0, 8), 16) * 1000);
}

export function isCreatedWithinLast15Minutes(accountId) {
  const createdAt = dateCreatedFromMongoId(accountId);
  const createdAtTimestamp = createdAt.getTime();
  const now = Date.now();
  return now - createdAtTimestamp < 15 * 60 * 1000;
}
