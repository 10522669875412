import { initIntercom } from '@mathieustan/vue-intercom';
import store from '@/store';

const appId = process.env.VUE_APP_INTERCOM_APP_ID;

const intercom = appId ? initIntercom({ appId }) : null;
const CHECKLISTS_WHO_SEES_WHAT_ID = 44665563;
const CHECKLISTS_DISCOVER_CORE_CAPABILITIES_ID = 44686407;

function rebootIntercom(user) {
  intercom.shutdown();

  if (intercom.isBooted) return;
  intercom.boot(user);
}

export function showIntercom() {
  intercom.show();
}

export function openChecklists(id) {
  if (intercom) {
    intercom.show();
    switch (id) {
      case 'who_sees_what':
        intercom.startTour(CHECKLISTS_WHO_SEES_WHAT_ID);
        break;
      case 'discover_core_capabilities':
        intercom.startTour(CHECKLISTS_DISCOVER_CORE_CAPABILITIES_ID);
        break;
      default:
        break;
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export async function startIntercomMessenger(isTrial) {
  if (!process.env.VUE_APP_INTERCOM_APP_ID) return;
  const {
    email, first_name, last_name, user_id
  } = window.Knack.Api;
  const user = {
    email,
    name: `${first_name} ${last_name}`,
    userId: user_id,
    app_slug: store.getters.app.slug,
  };

  if (isTrial) {
    const firstObject = store.getters.objects.filter((o) => o.attributes.type === 'StandardObject')[0];
    const firstScene = store.getters.allPages[0]?.raw.key;
    // user may have deleted all objects and scenes
    if (firstObject && firstScene) {
      user.current_valid_object = firstObject.key;
      user.current_valid_scene = firstScene;
    }
  }
  if (!intercom.ready) {
    intercom.once('ready', () => rebootIntercom(user));
  } else {
    rebootIntercom(user);
  }
}

export function trackIntercomEvent(eventName, metadata = null) {
  // we may not have intercom loaded in some envs or plans
  if (intercom) {
    intercom.trackEvent(eventName, metadata);
  }
}
